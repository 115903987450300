import { Row } from '../../common/theme/Row'
import { Column } from '../../common/theme/Column'
import { Section } from '../../common/theme/Section'

import Calendar from '../../common/img/calendar_blue.svg'
import './style.sass'
import { Slider } from './Slider'

import { Swiper, SwiperSlide } from 'swiper/react';

import BeforeAfter1 from '../../common/img/vocher-nacher/vocher-nacher-1.webp'
import BeforeAfter2 from '../../common/img/vocher-nacher/vocher-nacher-2.webp'
import BeforeAfter3 from '../../common/img/vocher-nacher/vocher-nacher-3.webp'
import BeforeAfter4 from '../../common/img/vocher-nacher/vocher-nacher-4.webp'

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

import { Pagination, Navigation, Autoplay } from 'swiper/modules';
import { Container } from '../../common/theme/Container'


export const Results = (props) => {
    return (
        <div id='results' className='relative font-white'>
            <Container className='xs-p-10 sm-p-0'>


                <Row className='xs-m-50-b'>
                    <div className='static-row'>

                            <h4 className='title'>Vocher/<br />Nacher</h4>
               

                    </div>
                    <Slider />
                    <Column className='relative desktop aaa'>
                        <Swiper
                            loop={true}
                            autoplay={{
                                delay: 2000,
                                disableOnInteraction: false,
                                pauseOnMouseEnter: false,

                            }}
                            slidesPerView={4}

                            navigation={true}
                            pagination={{
                                clickable: true,
                            }}
                            modules={[Pagination, Navigation, Autoplay]}
                            className="desktop-slider"
                        >
                            <SwiperSlide>
                                <div className='card slider-card'>
                                    <img src={BeforeAfter1} alt='before after' />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='card slider-card'>
                                    <img src={BeforeAfter2} alt='before after' />
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className='card slider-card'>
                                    <img src={BeforeAfter3} alt='before after' />
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className='card slider-card'>
                                    <img src={BeforeAfter4} alt='before after' />
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className='card slider-card'>
                                    <img src={BeforeAfter1} alt='before after' />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='card slider-card'>
                                    <img src={BeforeAfter2} alt='before after' />
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className='card slider-card'>
                                    <img src={BeforeAfter3} alt='before after' />
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className='card slider-card'>
                                    <img src={BeforeAfter4} alt='before after' />
                                </div>
                            </SwiperSlide>



                        </Swiper>

                    </Column>
                </Row>
                <div className='relative'>
                    <a href='/termin'>
                        <button className='termin button-style-3'>

                            <img src={Calendar} alt='calendar' />
                            <span>Jetzt Termin vereinbaren</span>



                        </button>
                    </a>
                    <Row className='sm-m-20-t'>
                        <Column className='card result-frame bg-1'>
                        </Column>
                        <Column className='xs-m-10-t sm-m-0-t card result-frame bg-2'>
                        </Column>
                    </Row>

                    <Row className='desktop'>
                        <Column className='card result-frame sm-m-20-t bg-3'>
                        </Column>
                    </Row>
                </div>
            </Container>
        </div>
    )
}