import { Column } from '../../common/theme/Column'
import { Section } from '../../common/theme/Section'
import { Container } from '../../common/theme/Container'
import LocationImg from '../../common/img/location.png'
import './style.sass'


export const Location = (props) => {
    return (
      <div id='location'>

        <Section>
            <h3 className='font-white title'>Unser<br/>Standort</h3>
            <strong className=''>Tödistrasse 62, 8810 horgen</strong>
        </Section>
        <img src={LocationImg} alt='location' width='100%' height='auto' />

      </div>
        
    )
}