import { Container } from "../../common/theme/Container"


export const AllgemeineGeschäftsbedingungen = (props) => {

    return (
        <Container className='section'>
            <h3 className='relative xs-m-50-t sm-m-0-t'>AllgemeineGeschäftsbedingungen</h3>
            <h4 className="font-md xs-m-50-t xs-m-25-b">Web & Fotografie</h4>

            <p className="xs-m-10-t">
                Zuständig für die Umsetzung der Webseite war Sergejs Alohins Alle Bilder und Inhalte sind Copyright geschützt und gehören
                ausschliesslich Max White.
            </p>
            <h4 className="font-md xs-m-50-t xs-m-25-b">Termine & Stornierungen</h4>

            <p className="xs-m-10-t xs-m-10-b">
                Bei nicht erscheinen am vereinbarten Termin müssen wir leider eine Stornogebühr von 100.- in Rechnung stellen
                (Ausnahme bei Arztzeugnissen). In Bezug auf sämtliche Behandlungen gewähren wir eine exklusive Bemängelungsfrist
                von 7 Tagen, insofern Sie mit Ihrer Behandlung bei der Max White nicht zufrieden sind. Melden Sie sich
                diesbezüglich bitte telefonisch oder via Email innerhalb der genannten Frist von 7 Tagen. Vielen herzlichen Dank!
            </p>


            <h4 className="font-md xs-m-50-t xs-m-25-b">Copyright & Datenschutz</h4>
            <p className="xs-m-10-t">
                Verantwortlich für den Inhalt dieser Webseite ist Max White. Die Urheber- und alle anderen Rechte an sämtlichen
                Inhalten und anderweitigen Dateien auf der Webseite gehören ausschliesslich Max White oder den speziell genannten
                Rechtsinhabern. Für die Reproduktion jeglicher Elemente ist die schriftliche Zustimmung der Urheberrechtsträger im
                Voraus einzuholen. Gestützt auf das Datenschutzrecht des Bundes (DSG) hat jede Person Anspruch auf Schutz Ihrer
                Privatsphäre, sowie auf Schutz vor Missbrauch der persönlichen Daten. Persönliche Daten werden streng vertraulich
                behandelt und weder verkauft noch weiter gegeben.
            </p>
            <h4 className="font-md xs-m-50-t xs-m-25-b">Haftungsausschluss</h4>
            <p className="xs-m-10-t">Der Autor übernimmt keinerlei Gewähr hinsichtlich der inhaltlichen Richtigkeit, Genauigkeit, Aktualität, Zuverlässigkeit
                und Vollständigkeit der Informationen. Haftungsansprüche gegen den Autor wegen Schäden materieller oder immaterieller
                Art, welche aus dem Zugriff oder der Nutzung bzw. Nichtnutzung der veröffentlichten Informationen, durch Missbrauch
                der Verbindung oder durch technische Störungen entstanden sind, werden ausgeschlossen. Der Autor behält es sich
                ausdrücklich vor, Teile der Seiten oder das gesamte Angebot ohne gesonderte Ankündigung zu verändern, zu ergänzen,
                zu löschen oder die Veröffentlichung zeitweise oder endgültig einzustellen.
            </p>
            <h4 className="font-md xs-m-50-t xs-m-25-b">Haftungsausschluss</h4>
            <p className="xs-m-10-t">
                Jegliche Verantwortung für die Verweise & Links auf Webseiten Dritter werden abgelehnt. Der Zugriff und die Nutzung 
                solcher Webseiten erfolgen auf eigene Gefahr des Nutzers:in.
            </p>

        </Container>
    )
}
