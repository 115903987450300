
import { Container } from '../../common/theme/Container'

import { useNavigate } from 'react-router'
import Calendar from '../../common/img/calendar_blue.svg'
import Logo from '../../common/img/logo_right_layout.svg'
import Menu from '../../common/img/menu.svg'
import './style.sass'
import { useState, useEffect } from 'react'

export const Navigation = (props) => {
    const path = window.location.pathname
    const navigate = useNavigate()
    const [navbar, setNavbar] = useState(path != '/')
    const [mobileMenu, setMobileMenu] = useState(false)

    const scrollTo = (elementId, position) => {
        if (elementId)
            document.getElementById(elementId).scrollIntoView({ behavior: 'smooth', block: position, inline: 'nearest' })
        else
            document.body.scrollIntoView({ behavior: 'smooth', blcok: 'position', inline: 'nearest' })
    }



    const switchNavbar = () => {
        if (path == '/')
            setNavbar(window.scrollY != 0);        
    }

    useEffect(() => {
        window.addEventListener("scroll", switchNavbar);
    }, [])

    return (
        <>
            <nav className={'navbar nav-scroll'}>                
                <Container>
                    <div className='static-row justify-space-between nav-container font-white xs-p-10 sm-p-0'>
                        <div className='logo' style={{cursor:'pointer'}} onClick={ async () => {
                            await navigate('/')
                            
                            
                            scrollTo('', 'start')
                        }}>
                            <img className='nav-logo' id='logo' src={Logo} alt='MaxWhite Logo' height='100%' width='100%' />
                        </div>
                        

                        <ul className='desktop'>
                            <li onClick={async () => {
                                await navigate('/')
                                scrollTo('about', 'center')
                            }}>
                                Über uns
                            </li>
                            <li onClick={async () => {
                                await navigate('/')
                                scrollTo('testimonials', 'center')
                            }}>
                                Referenzen
                            </li>
                            <li onClick={async () => {
                                await navigate('/')
                                scrollTo('faq', 'center')
                            }}>
                                Faq
                            </li>
                            <li>
                                <a href='/termin'>
                                    <button
                                        className='button-style-1'
                                    >
                                        <img src={Calendar} alt='calendar' />
                                        <span><b>Termin</b> vereinbaren</span>
                                    </button>
                                </a>

                            </li>
                        </ul>

                        <button
                            className='mobile menu-button'
                            onClick={() => setMobileMenu(!mobileMenu)}
                        >
                            <img src={Menu} alt='menu' />
                        </button>
                    </div>
                </Container>

            </nav>


            <div className={`mobile-menu ${mobileMenu && 'mobile-menu-active'}`}>
                <ul>                
                            <li onClick={async () => {
                                setMobileMenu(false)
                                await navigate('/')
                                scrollTo('about', 'center')
                            }}>
                                Über uns
                            </li>
                            <li onClick={async () => {
                                setMobileMenu(false)
                                await navigate('/')
                                scrollTo('testimonials', 'center')
                            }}>
                                Referenzen
                            </li>
                            <li onClick={async () => {
                                setMobileMenu(false)
                                await navigate('/')
                                scrollTo('faq', 'start')
                            }}>
                                Faq
                            </li>
                            <li>
                                <a href='/termin'>
                                    <button
                                        className='button-style-1'
                                    >
                                        <img src={Calendar} alt='calendar' />
                                        <span><b>Termin</b> vereinbaren</span>
                                    </button>
                                </a>

                            </li>
                </ul>
            </div>
        </>
    )
}