import { Section } from '../../common/theme/Section'
import { Row } from '../../common/theme/Row'
import { Column } from '../../common/theme/Column'
import { Container } from '../../common/theme/Container'

import Logo from '../../common/img/logo_right_layout.svg'
import Insta from '../../common/img/insta.svg'
import './style.sass'

export const Footer = (props) => {
    return (
        <footer id='footer' className='bg-gray'>
            <Container className='sm-p-100-t xs-p-25-t sm-p-50-b xs-p-10 sm-p-0'>
                <div className='relative xs-m-25-b sm-m-0-b'>
                    <Column className='align-items-left xs-m-25-b'>
                        <img src={Logo} alt='Logo' className='footer-logo' />
                        <span><strong>Öffnungszeiten: </strong>Termine nach Vereinbarung</span>
                    </Column>

                    <Row>
                        <Column className='footer-links'>
                            <div className='sm-p-150-r'>
                                <h4 className='font-sm xs-m-20-b'>Wichtige Links</h4>
                                <ul>
                                    <li><a href='/impressum'>Impressum</a></li>
                                    <li><a href='/datenschutz'>Datenshutz</a></li>
                                    <li><a href='/allgemeineGeschäftsbedingungen'>AllgemeineGeschäftsbedingungen</a></li>                                                                                                                                                
                                </ul>
                            </div>

                        </Column>
                    </Row>
                    <a href='https://www.instagram.com/maxwhite.ch/' className='insta-logo'>
                        <img src={Insta} alt='instagram'  height={'100%'} width={'100%'}/> 
                    </a>
                        

                    
              
                </div>

                <hr className='sm-m-50-t xs-m-10-b' />
                <span className='copy'>© 2024 Max White. Alle Rechte vorbehalten.</span>
            </Container>
        </footer>
    )
}