import { Column } from '../../common/theme/Column'
import { Row } from '../../common/theme/Row'
import { Container } from '../../common/theme/Container'

import LogoText from '../../common/img/maxWhite-logo-text.svg'

import './style.sass'

export const Header = (props) => {
    return (
        <div id='header' className='relative'>
            
            <Container>
                <div className='special-offer'>
                    <Column>
                        <span className='bold-3'>Bleaching-Special:</span>
                        <span>Das Max Bleaching für nur 175 CHF (<span style={{textDecoration:'line-through'}}>239 CHF</span>)</span>
                        <a className='font-white full-width' href='/termin'><button className='full-width'>SPECIAL SICHERN</button></a>
                    </Column>
                </div>
                <Column className='header-content align-items-center justify-center'>
                    <img src={LogoText} width={'50%'} height={'auto'} alt='Max White' />
                    <h1>Professionelles Zahnbleaching für Sie!</h1>
                </Column>
            
                

            </Container>

        </div>
    )
}