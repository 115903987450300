import { useState } from "react";
import { About } from "./components/About";
import { ActionCall } from "./components/ActionCall";
import { Faq } from "./components/Faq";
import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import { Location } from "./components/Location";
import { Navigation } from "./components/Navigation";
import { Offer } from "./components/Offer";
import { Results } from "./components/Results";
import { Scheduler } from "./components/Scheduler";
import { Testimonials } from "./components/Testimonials";
import { ParticlesComponent } from "./components/About/ParticlesComponent";
import { Impressum } from "./components/Impressum";
import { Datenschutz } from "./components/Datenschutz";
import { AllgemeineGeschäftsbedingungen } from "./components/AllgemeineGeschäftsbedingungen";
import { Route, Routes } from "react-router";


function App() {  
  const home = (
    <>
     <Header />
      <div id='bg-section-2'>      
      <ParticlesComponent />
      <About />
      <Results />
      </div>
      <Testimonials />
      <Location />
      <Faq />
      <Offer />
      <ActionCall />
    </>
  )

  return (
    <div className="App"
      
    
    >
      <Navigation />

      <Routes>
        <Route path='/' element={ home } />
        <Route path='/termin' element={<Scheduler />} />
        <Route path='/impressum' element={<Impressum />} />
        <Route path='/datenschutz' element={<Datenschutz />} />
        <Route path='/allgemeineGeschäftsbedingungen' element={<AllgemeineGeschäftsbedingungen />} />

      </Routes>          
      

      <div style={{height:'460px'}} className="desktop"/>  
      <div style={{height:'400px'}} className="mobile"/>  
      
      <Footer />
    </div>
  );
}

export default App;
