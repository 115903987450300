import { InlineWidget, PopupWidget } from "react-calendly";

import './style.sass'
import { Column } from "../../common/theme/Column";
import { useState } from "react";
import { Container } from "../../common/theme/Container";
import { Section } from "../../common/theme/Section";

import Back from '../../common/img/back.svg'
import Special from '../../common/img/special.svg'
import Next from '../../common/img/next.svg'
import Duration from '../../common/img/duration.svg'
import Price from '../../common/img/price.svg'

export const Scheduler = (props) => {
    const [calendly, toggleCalendly] = useState(false)
    const [service, setService] = useState(null)

    return (

        <div id='scheduler'>
            <Container className='xs-p-10 sm-p-0 sm-m-100-t sm-m-100-b'>
                <div className="mobile" style={{ height: '100px' }} />
                {
                    !calendly &&
                    <div className="service-container">
                        <div className="static-row service-title full-width bg-primary">

                            <h4>Behandlungstermine</h4>
                        </div>

                        <div className="service-list">
                            <div className="service xs-p-10" onClick={async () => {
                                await setService('https://calendly.com/maxwhitehorgen/max-bleaching')
                                toggleCalendly(true)
                            }}>
                                <div className="static-row xs-m-10-b">
                                    <h5 className="font-sm">MAX BLEACHING</h5>
                                    <span className="static-row service-special">
                                        <img src={Special} alt='special' />
                                        Special Sichern
                                    </span>
                                </div>

                                <div className="static-row service-stats">

                                    <div className="static-row">
                                        <span><img src={Duration} alt="duration" /> 60 min</span>
                                        <span className="bold-3"><img src={Price} alt='preis' /> 175 CHF</span>
                                        <span className="bold-3 discount"> 349 CHF</span>
                                    </div>

                                </div>
                                <hr />

                            </div>


                            <div className="service xs-p-10" onClick={async () => {
                                await setService('https://calendly.com/maxwhitehorgen/max-bleaching-klon')
                                toggleCalendly(true)
                            }}>
                                <h5 className="font-sm xs-m-10-b">Refresh</h5>

                                <div className="static-row service-stats">
                                    <span><img src={Duration} alt="duration" /> 20 min</span>
                                    <span className="bold-3"><img src={Price} alt='preis' /> 99 CHF</span>
                                </div>
                                <hr />
                            </div>


                            <div className="service xs-p-10" onClick={async () => {
                                await setService('https://calendly.com/maxwhitehorgen/30min')
                                toggleCalendly(true)
                            }}>
                                <h5 className="font-sm xs-m-10-b">Hollywood Bleaching</h5>
                                <div className="static-row service-stats">
                                    <div className="static-row">
                                        <span><img src={Duration} alt="duration" /> 40 min</span>
                                        <span className="bold-3"><img src={Price} alt='preis' /> 249 CHF</span>
                                        <span className="bold-3 discount"> 299 CHF</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                }






                {
                    calendly &&
                    <div>

                        <InlineWidget
                            url={service}
                            styles={{
                                height: '700px',
                                overflow: 'none',

                            }}

                            pageSettings={{
                                backgroundColor: 'ffffff',
                                hideEventTypeDetails: true,
                                hideLandingPageDetails: true,
                                primaryColor: '#5CC6D0',
                                textColor: '4d5055'
                            }}
                        />
                    </div >

                }
            </Container>

        </div>
    )
}