import { useState } from "react"
import { motion, AnimatePresence } from "framer-motion"

export const Accordion = props => {
    const [expanded, setExpanded] = useState(false)

    const contentStyle = props.contentStyle

    return (
        <>
            <motion.header 
                initial={false}
                aniamte={{ backgroundColor: expanded ? "#FF0088" : "#0055FF"}}
                onClick={() => setExpanded(!expanded)}
                className={`full-width relative ${props.className}`}
                style={{cursor:'pointer', boxSizing: "border-box"}}
            >

                {props.header}
                <motion.button 
                    className={` dropdown-btn-open font-lg ${expanded ? 'dropdown-btn-close' : ''}`}                     
                    //transition={{ type: "spring", stiffness: 400, damping: 10 }}
                >
                    
                    +
                </motion.button> 
            </motion.header>
            <AnimatePresence initial={false} >
            {
                expanded && (
                    <motion.section
                        key="content"
                        initial="collapsed"
                        animate="open"
                        exit="collapsed"
                        variants={{
                            open: { opacity: 1, height: "auto"},
                            collapsed: { opacity: 0, height: 0 }
                        }}                        
                        transition={{duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98]}}
                    >
                        <div className={props.contentClassName}>
                            {props.content}
                            
                        </div>
                        
                    </motion.section>                    
                )                
            }
            </AnimatePresence>
        </>
            
    )
}