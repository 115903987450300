import { Column } from '../../common/theme/Column'
import { Container } from '../../common/theme/Container'
import { Section } from '../../common/theme/Section'
import logo from '../../common/img/bleaching.webp'
import { ParticlesComponent } from './ParticlesComponent'
import './style.sass'

export const About = (props) => {
    return (
        <>

            <div className='section' id='about'>

                <Container>

                    <Column className='card relative'>
                        <h3 className='font-white sm-m-50-l'>Über uns</h3>
                        <p className='xs-m-50-b sm-m-0-b font-sm bold-1'>
                            „Unsere Philosophie ist es, unseren Kunden zu einem strahlenden Lächeln zu verhelfen, das ihr Selbstbewusstsein stärkt und sie glücklicher macht.

                            Wir setzen dabei auf höchste Qualität und Kompetenz sowie eine angenehme und entspannte Atmosphäre, in der sich unsere Kunden rundum wohlfühlen.

                            Wir sind stolz darauf, unseren Teil dazu beizutragen, dass unsere Kunden ihr volles Potenzial ausschöpfen können, indem wir ihnen zu einem schöneren Lächeln verhelfen.“– M. Koral, Max White
                        </p>
                        <img src={logo} alt='logo' />
                    </Column>
                </Container>

            </div>
        </>
    )
}