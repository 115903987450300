import { Row } from '../../common/theme/Row'
import { Column } from '../../common/theme/Column'
import { Accordion } from '../../common/theme/Accordion'
import { Section } from '../../common/theme/Section'

import Background from '../../common/img/maxWhite-girl.png'
import Calendar from '../../common/img/calendar_blue.svg'
import Logo from '../../common/img/maxWhite-logo-white.svg'
import './style.sass'

export const ActionCall = (props) => {
    return (
        <Section id='action-call'>
            <Row className='relative'>
                <Column className='card action-call-container justify-center  font-white'>
                    <h2 className='font-xl'>REFRESH?</h2>
                    <p className='bold-1 xs-m-25-t bold-2'>
                        Du hast schon eine Behandlung bei uns genossen? Dann sicher dir deinen günstigeren Refresh-Termin!
                    </p>
                    <div className='static-row xs-m-50-t'>
                        <a href='/termin' className='full-width'>
                            <button className='termin button-style-3'>
                                <img src={Calendar} alt='calendar' />
                                <span>Jetzt Termin vereinbaren</span>

                            </button>
                        </a>
                        
                    </div>
                   
                </Column>
                <Column className='sm-m-20-l action-call-background'>
                    <img src={ Background } alt='background' />
                </Column>
            </Row>
        </Section>
    )
}