import { Row } from '../../common/theme/Row'
import { Column } from '../../common/theme/Column'
import { Accordion } from '../../common/theme/Accordion'
import { Section } from '../../common/theme/Section'
import './style.sass'

export const Faq = (props) => {
    return (
        <Section id='faq' className='bg-white'>
            <div className='static-row dynamic-title'>
                <h3 className='font-primary'>FAQ</h3>
            </div>
            

            <Row className='faq-container relative'>
                <Column className='faq-column faq-row-container sm-m-10-r'>
                    <Accordion 
                        header='Schadet das Bleaching meinen Zähnen?' 
                        content={`
                            Nein. Die Behandlung verändert weder die Struktur Ihrer 
                            Zähne noch hat sie eine nachteilige Auswirkung auf Füllungen. 
                            Sie beschädigt auch nicht Ihren Zahnschmelz.                           
                        `}
                        className='faq-card'
                        contentClassName='faq-card-content' 
                    />
                    <Accordion 
                        header='Ist die Behandlung schmerzhaft?' 
                        content={`
                            Machen Sie sich keine Sorgen, unsere Behandlung ist 100% 
                            sicher und schmerzfrei! Die Behandlung verändert weder die 
                            Struktur Ihrer Zähne noch hat sie eine nachteilige Auswirkung 
                            auf Füllungen. Sie beschädigt auch nicht Ihren Zahnschmelz.                           
                        `}
                        className='faq-card'
                        contentClassName='faq-card-content' 
                    />
                    <Accordion 
                        header='Wie funktioniert die Zahnaufhellung?' 
                        content={`
                            Bei der Zahnaufhellung verlieren organische Substanzen, welche 
                            auf dem Zahnschmelz eingelagert sind, ihre Farbe. Unser Gel enthält 
                            ein aktives Bleichmittel, welches durch das LED-Licht aktiviert wird. 
                            Auf der Zahnsubstanz kommt es zur Oxidation. Die hierbei entstehenden 
                            Sauerstoffradikale sorgen dafür, dass die eingelagerten Farbstoffe 
                            ihre Farbe verlieren.                         
                        `}
                        className='faq-card'
                        contentClassName='faq-card-content'
                    />
                    <Accordion 
                        header='Wie kommt es zu Verfärbungen der Zähne?' 
                        content={`
                            Hierfür gibt es viele Ursachen. Zum einen ist der Konsum von farbintensiven 
                            Lebensmitteln wie Cola, Tee, Kaffee, Wein und besonders das Rauchen 
                            für die Verfärbung verantwortlich. Aber auch Medikamente können für 
                            Gelbfärbungen der Zähne verantwortlich sein. Andere Gründe wie Brüche 
                            oder Stösse durch Unfälle können zu Verdunkelungen führen. Nicht zu vergessen 
                            ist die Zahnpflege.                        
                        `}
                        className='faq-card'
                        contentClassName='faq-card-content' 
                    />
                    <Accordion 
                        header='Welche Zahlungsmittel werden akzeptiert?' 
                        content={`
                            Bar, Twint, Kartenzahlung (keine American Express)
                        `}
                        className='faq-card'
                        contentClassName='faq-card-content' 
                    />
                    
                </Column>
                <Column className='faq-column faq-row-container sm-m-10-l'>
                <Accordion 
                        header='Wie lange hält die Aufhellung?' 
                        content={`
                            Das hängt sehr stark von der Zahnpflege und den Konsumgewohnheiten ab. Die 
                            Wirkung der Zahnaufhellung kann bis zu 2-3 Jahre oder nur 6 Monate dauern. 
                            Das ist von Person zu Person unterschiedlich.

                            Nach der Behandlung werden wir Ihnen einige Tipps geben, damit Ihre Zähne 
                            weiß und strahlend bleiben!                          
                        `}
                        className='faq-card'
                        contentClassName='faq-card-content' 
                    />
                    <Accordion 
                        header='Wann sollte keine Aufhellung durchgeführt werden?' 
                        content={`
                            Bei einer Allergie gegen einer der Inhaltsstoffe. Bei Schwangeren, während 
                            der Stillzeit und Chemotherapie-Patienten. Bei Problemen mit den Zähnen und/ 
                            oder Zahnfleisch.                           
                        `}
                        className='faq-card'
                        contentClassName='faq-card-content'
                    />
                    <Accordion 
                        header='Was sollte vor der Behandlung beachtet werden?' 
                        content={`
                            Professionelle Zahnreinigung ist zu empfehlen, damit vorhandene Beläge entfernt werden, 
                            die ein Hindernis für das Gel darstellen können. Eine Aufhellung funktioniert nur 
                            bei natürlichen Zähnen. Implantate, Brücken und Kronen bleiben völlig unberührt. 
                            Es kann nur der natürliche Farbton wieder hergestellt werden. Die Zahnsubstanz 
                            wird nicht verändert.                       
                        `}
                        className='faq-card'
                        contentClassName='faq-card-content' 
                    />
                    <Accordion 
                        header='Was sollte nach der Behandlung beachtet werden?' 
                        content={`
                            Während der Behandlung wird dem Zahn etwas Wasser entzogen, das jedoch schnell vom Zahn wieder 
                            eingelagert wird. In dieser Zeit nehmen die Zähne Zahnverfärbungen leichter auf, daher 
                            sollte man auf Rauchen und den Verzehr von stark färbenden Lebensmitteln, 12 Stunden lang 
                            verzichten. Mindestens 1 Stunde nach der Behandlung sollte nur Wasser getrunken werden.                          
                        `}
                        className='faq-card'
                        contentClassName='faq-card-content' 
                    />
                    
                </Column>                                                        
            </Row>
        </Section>
    )
}