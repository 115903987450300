import { Container } from '../Container/'

export const Section = (props) => {
    return (
        <div id={props.id} className={`section ${props.className}`}>
            <Container> 
                {props.children}   
            </Container>             
        </div>
    )
}
