import { Row } from '../../common/theme/Row'
import { Column } from '../../common/theme/Column'
import { Section } from '../../common/theme/Section'
import './style.sass'

import Avatar1 from '../../common/img/avatars/avatar-1.JPG'
import Avatar2 from '../../common/img/avatars/avatar-2.JPG'
import Avatar3 from '../../common/img/avatars/avatar-3.JPG'

export const Testimonials = (props) => {
    return (
        <Section id='testimonials' className='font-white'>
            <div className='static-row dynamic-title'>
                <h3>Referenzen</h3>
            </div>
            
            <Row>
                <Column className='card sm-m-10-r testimonial-card'>
                    <div className='text-center'>
                        <div className='testimonial-avatar'>
                            <img src={Avatar1} alt='avatar' height={'100%'} />
                        </div>
                        <span className='sm-m-25-t font-sm'>Marco O.</span>
                        
                    </div>
                    
                    <cite>                    
                        Tolles Studio! Sehr sauber und steril. Super freundlicher Inhaber. 
                        War anfangs skeptisch aber meine Zähne waren nach dem VIP-Bleaching 
                        strahlend weiß! Ich bin total begeistert und empfehle Max White 
                        sehr gerne weiter.
                        
                    </cite>
                </Column>
                <Column className='card sm-m-10-r testimonial-card'>
                    <div className='text-center'>
                        <div className='testimonial-avatar'>
                            <img src={Avatar2} alt='avatar' height={'100%'} />
                        </div>
                        <span className='sm-m-25-t font-sm'>Paula H.</span>
                    </div>
                    
                    <cite>                    
                        Sehr freundlicher und kompetenter Inhaber. Ruhige gemütliche Behandlung, 
                        meine Zähne waren bereits nach den ersten 20 min des Bleaching 
                        deutlich weißer und nach 60 min war das Bleaching abgeschlossen 
                        und ich habe mein Traumergebnis erreicht! Bin sehr begeistert und 
                        werde es definitiv weiterempfehlen! Bin übrigens Nichtraucher und 
                        trinke auch kein Kaffee.
                        
                    </cite>
                </Column>
                <Column className='card sm-m-10-r testimonial-card'>
                    <div className='text-center'>
                        <div className='testimonial-avatar'>
                            <img src={Avatar3} alt='avatar' height={'100%'} />
                        </div>
                        <span className='sm-m-25-t font-sm'>Sandra M.</span>
                    </div>
                    
                    <cite>                    
                        Ich habe mich bei Max White sehr wohl gefühlt. Das Ergebnis war 
                        mehr als verblüffend. Meine durch Rauchen gelbverfärbten Zähne 
                        wurden wieder strahlend weiß. Die Zahnaufhellung war völlig schmerzfrei 
                        und es war eine sehr angenehme Atmosphäre.                        
                    </cite>
                </Column>
                
            </Row>
        </Section>
    )
}