import { Container } from "../../common/theme/Container"


export const Impressum = (props) => {

    return (
        <Container className='section'>
            <h3 className='xs-m-50-t sm-m-0-t'>Impressum</h3>
            <p className="xs-m-50-t font-sm">
                Koral GmbH <br />
                Tödistrasse 62 8810 Horgen
            </p>

            <p className="xs-m-25-t font-sm">
                Handelsregister-Nummer CHE-339.816.008 <br />                
            </p>
        </Container>
    )
}
