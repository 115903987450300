import './style.sass'

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';

import BeforeAfter1 from '../../../common/img/vocher-nacher/vocher-nacher-1.webp'
import BeforeAfter2 from '../../../common/img/vocher-nacher/vocher-nacher-2.webp'
import BeforeAfter3 from '../../../common/img/vocher-nacher/vocher-nacher-3.webp'
import BeforeAfter4 from '../../../common/img/vocher-nacher/vocher-nacher-4.webp'


import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Pagination, Autoplay } from 'swiper/modules';

export const Slider = (props) => {
    return (
        <div className='static-row justify-center mobile xs-m-50-t'>
            <Swiper
                effect={'coverflow'}
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={'auto'}
                loop={true}
                autoplay={{
                    delay: 2000,
                    disableOnInteraction: true,
                    pauseOnMouseEnter: false,

                }}
                coverflowEffect={{
                    rotate: 50,
                    stretch: 0,
                    depth: 100,
                    modifier: 1,
                    slideShadows: true,
                }}
                pagination={true}
                modules={[EffectCoverflow, Pagination, Autoplay]}
                className="mySwiper"
            >
                <SwiperSlide>
                    <img src={BeforeAfter1} alt='before-after-1' />
                </SwiperSlide>
                <SwiperSlide>
                    <img src={BeforeAfter2} alt='before-after-2' />
                </SwiperSlide>
                <SwiperSlide>
                    <img src={BeforeAfter3} alt='before-after-3' />
                </SwiperSlide>
                <SwiperSlide>
                    <img src={BeforeAfter4} alt='before-after-4' />
                </SwiperSlide>

            </Swiper>

        </div >

    )
}