import { Column } from '../../common/theme/Column'
import { Row } from '../../common/theme/Row'
import { Section } from '../../common/theme/Section'
import Calendar from '../../common/img/calendar_blue.svg'
import { OfferCard } from './OfferCard'
import './style.sass'

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-cards';
import 'swiper/css/pagination';

import { EffectCards, Pagination, Navigation, Autoplay } from 'swiper/modules';

export const Offer = (props) => {
    return (
        <Section id='offer'>
            <h3 className='font-white garantie-title'>Unsere Garantie</h3>

            <p className='xs-m-100-b font-white'>
                Wir sind von den Ergebnissen unserer einzigartigen Methode derart überzeugt, 
                dass wir für unsere VIP- und Hollywood-Smile-Behandlungen eine Ergebnisgarantie 
                gewähren: <span className='bold-3'>Sollte keine Verbesserung von mindestens 5 Farbnuancen erzielt werden, 
                greift unsere Geld-zurück-Garantie.</span> Vereinbare jetzt deinen Termin!
            </p>

            <h3 className='font-white'>Wähle die Behandlung, welche am besten zu Dir passt</h3>            
           
            <Row>
                <OfferCard
                    key={0}
                    name='Refresh'
                    oldPrice=''
                    newPrice='99'
                    duration='20'
                    description='Bis zu 5 Nuancen hellere Zähne in nur einer Sitzung.'          
                    points={[
                        '1 Gel Anwendungen',
                        '20 Minuten Behandlung',
                        'Schmerzfrei',
                        'Nebenwirkungsfrei',                        
                        'Ergebnis sofort sichtbar',
                        'Refresh-Karte inklusive',
                        ''
                    ]}

                    className='offer-secondary'

                ></OfferCard>
                 <OfferCard
                    key={1}
                    name='Max Bleaching'
                    oldPrice='349'
                    newPrice='175'
                    duration='60'
                    description='Bis zu 15 Nuancen hellere Zähne in nur einer Sitzung.'
                    special

                    points={[
                        '3 Gel Anwendungen',
                        '60 Minuten Behandlung',
                        'Schmerzfrei',
                        'Nebenwirkungsfrei',
                        'Geld zurück Garantie',
                        'Ergebnis sofort sichtbar',
                        'Refresh-Karte inklusive',
                    ]}

                    className=''

                ></OfferCard>
                <OfferCard
                    key={2}
                    name='Hollywood Bleaching'
                    oldPrice='299'
                    newPrice='249'
                    duration='40'
                    description='Bis zu 10 Nuancen hellere Zähne in nur einer Sitzung.'
                    points={[
                        '2 Gel Anwendungen',
                        '40 Minuten Behandlung',
                        'Schmerzfrei',
                        'Nebenwirkungsfrei',
                        'Geld zurück Garantie',
                        'Ergebnis sofort sichtbar',
                        'Refresh-Karte inklusive',
                    ]}

                    className='offer-secondary'

                ></OfferCard>
               

            </Row>
        </Section>
    )
}