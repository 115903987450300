
import { Column } from '../../../common/theme/Column'
import Calendar from '../../../common/img/calendar_blue.svg'

import SpecialOffer from '../../../common/img/special_offer.svg'
import Duration from '../../../common/img/duration.svg'

export const OfferCard = (props) => {
    return (

        <Column className={'card offer-card ' + props.className} key={props.key}>
            {
                props.special && <img className='special-offer' src={SpecialOffer} alt='Special Offer' />
            }

            

            <strong>{props.name}</strong>
            <hr className='xs-m-10-b'/>
            <div className='static-row justify-space-between offer-stats'>
                    <div className='offer-card-price'>
                        <Column>
                            {props.oldPrice && <span className='font-sm discount'>{props.oldPrice} CHF</span>}
                            
                            <span className='font-md bold-3'>{props.newPrice} CHF</span>
                        </Column>
                        
                    </div>
                    <div className='offer-card-duration'>
                        <span className='static-row font-sm'><img src={Duration} style={!props.special ? {filter: 'invert(1)'} : {}} className='xs-m-10-r' alt='duration'></img>{props.duration} min</span>
                    </div>
                
                

            </div>

            
            <p>
                {props.description}
            </p>
            <ul>
                {props.points.map((point) => <li><span className='font-primary xs-m-10-r'>{point && '✔'}</span> {point}</li>)}
            </ul>
            <div className='static-row justify-left full-width'>
                <a href='/termin' className='full-width'>
                    <button
                        style={props.special ? {border: '1px solid rgba(0, 0, 0, .1)'} : {border: '1px solid rgba(255, 255, 255, .5)'}}
                    
                    >
                        <img style={!props.special ? {filter: 'brightness(2)'} : {}} src={Calendar} alt='calendar' /><p>Termin sichern</p>
                    </button>
                </a>
            </div>
        </Column>



    )
}