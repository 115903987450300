import { Container } from "../../common/theme/Container"


export const Datenschutz = (props) => {

    return (
        <Container className='section'>
            <h3 className='relative xs-m-50-t sm-m-0-t'>Datenschutz</h3>
            <h4>Datenschutzbestimmungen</h4>

            <h4 className="font-md xs-m-50-t xs-m-25-b">Datenschutz auf einen Blick</h4>
            <b>Allgemeine Hinweise</b>
            <p className="xs-m-10-t">
                Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten
                passiert, wenn Sie unsere Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie persönlich
                identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen Sie unserer unter
                diesem Text aufgeführten Datenschutzerklärung.
            </p>
            <h4 className="font-md xs-m-50-t xs-m-25-b">Datenerfassung auf unserer Website</h4>
            <b>Wer ist verantwortlich für die Datenerfassung auf dieser Website?</b>
            <p className="xs-m-10-t xs-m-10-b">
                Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten können Sie
                dem Impressum dieser Website entnehmen.
            </p>
            <b>Wie erfassen wir Ihre Daten?</b>
            <p className="xs-m-10-t xs-m-10-b">
                Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z.B. um Daten handeln,
                die Sie in ein Kontaktformular eingeben.

                Andere Daten werden automatisch beim Besuch der Website durch unsere IT-Systeme erfasst. Das sind vor allem
                technische Daten (z.B. Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die Erfassung dieser
                Daten erfolgt automatisch, sobald Sie unsere Website betreten.
            </p>

            <b>Wofür nutzen wir Ihre Daten?</b>
            <p className="xs-m-10-t xs-m-10-b">
                Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu gewährleisten. Andere Daten
                können zur Analyse Ihres Nutzerverhaltens verwendet werden.
            </p>

            <b>Welche Rechte haben Sie bezüglich Ihrer Daten?</b>
            <p className="xs-m-10-t xs-m-10-b">
                Sie haben jederzeit das Recht unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer gespeicherten
                personenbezogenen Daten zu erhalten. Sie haben außerdem ein Recht, die Berichtigung, Sperrung oder Löschung
                dieser Daten zu verlangen. Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich jederzeit
                unter der im Impressum angegebenen Adresse an uns wenden. Des Weiteren steht Ihnen ein Beschwerderecht bei
                der zuständigen Aufsichtsbehörde zu.
            </p>

            <b>Analyse-Tools und Tools von Drittanbietern</b>
            <p className="xs-m-10-t xs-m-10-b">
                Beim Besuch unserer Website kann Ihr Surf-Verhalten statistisch ausgewertet werden. Das geschieht vor allem
                mit Cookies und mit sogenannten Analyseprogrammen. Die Analyse Ihres Surf-Verhaltens erfolgt in der Regel
                anonym; das Surf-Verhalten kann nicht zu Ihnen zurückverfolgt werden. Sie können dieser Analyse widersprechen
                oder sie durch die Nichtbenutzung bestimmter Tools verhindern. Detaillierte Informationen dazu finden Sie in
                der folgenden Datenschutzerklärung.

                Sie können dieser Analyse widersprechen. Über die Widerspruchsmöglichkeiten werden wir Sie in dieser
                Datenschutzerklärung informieren.
            </p>

            <h4 className="font-md xs-m-50-t xs-m-25-b">Allgemeine Hinweise und Pflichtinformationen</h4>
            <b>Datenschutz</b>
            <p className="xs-m-10-t xs-m-10-b">
                Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre 
                personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser 
                Datenschutzerklärung.

                Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben. Personenbezogene 
                Daten sind Daten, mit denen Sie persönlich identifiziert werden können. Die vorliegende Datenschutzerklärung 
                erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie erläutert auch, wie und zu welchem Zweck 
                das geschieht.

                Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) 
                Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist 
                nicht möglich.
            </p>
            <b>Hinweis zur verantwortlichen Stelle</b>
            <p className="xs-m-10-t">Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:</p>
            <p className="xs-m-10-t">Max White</p>
            <p className="xs-m-10-t">Tödistrasse 62 8810 Horgen</p>
            <p className="xs-m-10-t">Tel: +41 76 306 11 22</p>
            <p className="xs-m-10-t">info@maxwhite.ch</p>
            <p className="xs-m-10-t xs-m-10-b">
                Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder 
                gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z.B. Namen, 
                E-Mail-Adressen o. Ä.) entscheidet.
            </p>
            <b>Widerruf Ihrer Einwilligung zur Datenverarbeitung</b>
            <p className="xs-m-10-t xs-m-10-b">
                Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie können eine 
                bereits erteilte Einwilligung jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an uns. 
                Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
            </p>
            <b>Beschwerderecht bei der zuständigen Aufsichtsbehörde</b>
            <p className="xs-m-10-t xs-m-10-b">
            Mehr Information darüber finden Sie bei dem Eidgenössischen Datenschutz- und Öffentlichkeitsbeauftragter (EDÖB)
            </p>

            <b>Recht auf Datenübertragbarkeit</b>
            <p className="xs-m-10-t xs-m-10-b">
                Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags 
                automatisiert verarbeiten, an sich oder an einen Dritten in einem gängigen, maschinenlesbaren Format 
                aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen 
                verlangen, erfolgt dies nur, soweit es technisch machbar ist.
            </p>
            <b>
                SSL- bzw. TLS-Verschlüsselung
            </b>
            <p className="xs-m-10-t xs-m-10-b">
                Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, wie zum 
                Beispiel Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL-bzw. 
                TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des 
                Browsers von “http://” auf “https://” wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.

                Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, 
                nicht von Dritten mitgelesen werden.
            </p>
            <b>
                Auskunft, Sperrung, Löschung
            </b>
            <p className="xs-m-10-t xs-m-10-b">
                Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche 
                Auskunft über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck 
                der Datenverarbeitung und ggf. ein Recht auf Berichtigung, Sperrung oder Löschung dieser Daten. Hierzu 
                sowie zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit unter der im Impressum 
                angegebenen Adresse an uns wenden.
            </p>
            <b>
                Widerspruch gegen Werbe-Mails
            </b>
            <p className="xs-m-10-t xs-m-10-b">
                Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten zur Übersendung von nicht 
                ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit widersprochen. Die Betreiber 
                der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-E-Mails, vor.
            </p>        
        </Container>
    )
}
